/** Start Global JS Vars **/
/** End Global JS Vars **/

/** Start Global JS Functions **/

//function nextframe() {}

/** Start Page Show Effect **/
function gPagePreloader() {
  'use strict';
  var $ = jQuery;
  var $preloaderCnt = $('#g-page-preloader');
  var $genWrapperCnt = $('#g-page-wrapper');
  $preloaderCnt.fadeOut(1200, function() {
    $(this).addClass('g-state_hide');
  }).children().fadeOut(300);
  $genWrapperCnt.fadeIn(600, function() {
    $(this).addClass('g-state_show');
  });
}

/** End Page Show Effect **/

/** Start All Title Hide **/
function allTitleHide() {
  'use strict';
  var $ = jQuery;
  //var $elementWAttr = $('.navbar a, .navbar img, .gm-main-menu-block a, .gm-main-menu-block img, .g-p-footer a, .g-p-footer img');
  var $elementWAttr = $('a, img');
  $elementWAttr.each(function() {
    $(this).attr('data-hidden-title', $(this).attr('title')).removeAttr('title');
  });
}

/** End All Title Hide **/

/** Start Main Tag Calc min-height **/
function mainTagCalcMinHeight() {
  'use strict';
  var $ = jQuery;
  var elementHeight = 0;
  var $windowHeight = $(window).height();
  var $headerSpacerHeight = parseInt($('.g-p-main').css('padding-top'));
  var $footerSpacerHeight = parseInt($('.g-p-main').css('padding-bottom'));
  var $elementCalc = $('.g-p-header, .g-p-footer');
  var $elementSet = $('.g-p-main__panel-wrapper');
  if (!$('body').hasClass('g-page_mode_index')) {
    $elementCalc.each(function() {
      elementHeight = elementHeight + $(this).outerHeight(true);
    });
    if (elementHeight > 0) {
      $elementSet.css('min-height', ($windowHeight - elementHeight - $footerSpacerHeight));
    }
  }
}

/** End Main Tag Calc min-height **/

/** Start Input Mask Init (jQuery-Mask-Plugin) **/
function inputMaskInit_OLD() {
  'use strict';
  var $ = jQuery;
  var $elementMasked = $('input.jqinputmask[type="tel"]');
  var elementMask, elementMaskPlaceholder;
  if ($.fn.mask) {
    $elementMasked.each(function() {
      $(this).mask('+7(000)000-00-00', {
        placeholder: '+7(___)___-__-__'
      });
    });
  }
  $elementMasked = $('input.jqinputmask').not('[type="tel"]');
  if ($.fn.mask) {
    $elementMasked.each(function() {
      elementMask = ($(this).attr('data-mask') !== '' && $(this).attr('data-mask') !== undefined) ? $(this).attr('data-mask') : '';
      elementMaskPlaceholder = ($(this).attr('data-mask-placeholder') !== '' && $(this).attr('data-mask-placeholder') !== undefined) ? $(this).attr('data-mask-placeholder') : '';
      if (elementMask !== '') {
        $(this).mask(elementMask, {
          placeholder: elementMaskPlaceholder
        });
      }
    });
  }
}

/** End Input Mask (jQuery-Mask-Plugin) **/

/** Start Input Mask Init (jQuery-Inputmask-Plugin) **/
function inputMaskInit() {
  'use strict';
  var $ = jQuery;
  var $elementMasked = $('input.jqinputmask[type="tel"]');
  var elementMask, elementMaskPlaceholder;
  if ($.fn.inputmask) {
    $elementMasked.each(function() {
      $(this).inputmask({
        mask: '+7(999)999-99-99',
        placeholder: '+7(___)___-__-__',
        showMaskOnHover: false
      });
    });
  }
  $elementMasked = $('input.jqinputmask').not('[type="tel"]');
  if ($.fn.inputmask) {
    $elementMasked.each(function() {
      elementMask = ($(this).attr('data-mask') !== '' && $(this).attr('data-mask') !== undefined) ? $(this).attr('data-mask') : '';
      elementMaskPlaceholder = ($(this).attr('data-mask-placeholder') !== '' && $(this).attr('data-mask-placeholder') !== undefined) ? $(this).attr('data-mask-placeholder') : '';
      if (elementMask !== '') {
        $(this).inputmask({
          mask: elementMask,
          placeholder: elementMaskPlaceholder,
          showMaskOnHover: false
        });
      }
    });
  }
}

/** End Input Mask (jQuery-Inputmask-Plugin) **/

/** Start Page ScrollUp Init **/
function pageScrollUpInit() {
  'use strict';
  var $ = jQuery;
  var mScrollToTop = {
    offsetTop: 161,
    element: '#gp-scrolltotop-btn',
    duration: 300,
    easing: 'swing'
  };
  $(document).scroll(function() {
    if ($(this).scrollTop() > mScrollToTop.offsetTop) {
      $(mScrollToTop.element).addClass('visible');
    } else {
      $(mScrollToTop.element).removeClass('visible');
    }
  });
  $(mScrollToTop.element).click(function() {
    $('html, body').animate({
      scrollTop: 0
    }, mScrollToTop.duration);
    return false;
  });
}

/** End Page ScrollUp Init **/

/** Start Disabled Link Href Events **/
function disabledLinkHrefEventsInit() {
  'use strict';
  var $ = jQuery;
  var $elementDisabled = $('a.disabled-href');
  $elementDisabled.each(function() {
    $(this).on('click', function(e) {
      e.preventDefault();
    });
  });
}

/** End Disabled Link Href Events **/

/** Start YandexMaps Var1 **/
/** Start YandexMaps Init Var1 **/
function ymapsInit_var1() {
  'use strict';
  var $ = jQuery;
  var myMap, myPlacemark, MyBalloonLayout, MyBalloonContentLayout;
  myMap = new ymaps.Map("gp-widget-yamaps-1", {
    center: g_site_jsconfig.site_widget.gp_widget_yamaps_1.center,
    zoom: 12,
    size: ["100%", 350],
    controls: ["rulerControl"]
  }, {
    searchControlProvider: "yandex#search"
  }), myMap.controls.add("zoomControl", {
    position: {
      top: 90,
      left: 10
    }
  }), myMap.controls.add("geolocationControl", {
    position: {
      top: 10,
      left: 10
    }
  }), myMap.controls.add("routeEditor", {
    position: {
      top: 50,
      left: 10
    }
  }), myMap.controls.add("trafficControl", {
    position: {
      bottom: 110, // top: 10,
      right: 10 // right: 140
    }
  }), myMap.controls.add("typeSelector", {
    position: {
      bottom: 70, // top: 10,
      right: 10 // right: 50
    }
  }), myMap.controls.add("fullscreenControl", {
    position: {
      top: 10,
      right: 10
    }
  }), MyBalloonLayout = ymaps.templateLayoutFactory.createClass('<div class="popover top"><a class="close" href="#">&times;</a><div class="arrow"></div><div class="popover-inner">$[[options.contentLayout observeSize minWidth=235 maxHeight=320]]</div></div>', {
    build: function() {
      this.constructor.superclass.build.call(this), this._$element = $(".popover", this.getParentElement()), this.applyElementOffset(), this._$element.find(".close").on("click", $.proxy(this.onCloseClick, this));
    },
    clear: function() {
      this._$element.find(".close").off("click"), this.constructor.superclass.clear.call(this);
    },
    onSublayoutSizeChange: function() {
      MyBalloonLayout.superclass.onSublayoutSizeChange.apply(this, arguments), this._isElement(this._$element) && (this.applyElementOffset(), this.events.fire("shapechange"));
    },
    applyElementOffset: function() {
      this._$element.css({
        left: -(this._$element[0].offsetWidth / 2),
        top: -(this._$element[0].offsetHeight + this._$element.find(".arrow")[0].offsetHeight)
      });
    },
    onCloseClick: function(e) {
      e.preventDefault(), this.events.fire("userclose");
    },
    getShape: function() {
      if (!this._isElement(this._$element)) {
        return MyBalloonLayout.superclass.getShape.call(this);
      }
      var e = this._$element.position();
      return new ymaps.shape.Rectangle(new ymaps.geometry.pixel.Rectangle([
        [e.left, e.top],
        [e.left + this._$element[0].offsetWidth, e.top + this._$element[0].offsetHeight + this._$element.find(".arrow")[0].offsetHeight]
      ]));
    },
    _isElement: function(e) {
      return e && e[0] && e.find(".arrow")[0];
    }
  }), MyBalloonContentLayout = ymaps.templateLayoutFactory.createClass('<h3 class="popover-title">$[properties.balloonHeader]</h3><div class="popover-content">$[properties.balloonContent]</div>'), myPlacemark = window.myPlacemark = new ymaps.Placemark(g_site_jsconfig.site_widget.gp_widget_yamaps_1.placemark_1.center, {
    balloonHeader: g_site_jsconfig.site_widget.gp_widget_yamaps_1.placemark_1.balloonHeader,
    balloonContent: g_site_jsconfig.site_widget.gp_widget_yamaps_1.placemark_1.balloonContent
  }, {
    balloonShadow: false,
    balloonLayout: MyBalloonLayout,
    balloonContentLayout: MyBalloonContentLayout,
    balloonPanelMaxMapArea: 0,
    hideIconOnBalloonOpen: false,
    balloonOffset: [3, -25],
    iconColor: g_site_jsconfig.site_widget.gp_widget_yamaps_1.placemark_1.iconColor,
    preset: g_site_jsconfig.site_widget.gp_widget_yamaps_1.placemark_1.preset // https://tech.yandex.ru/maps/doc/jsapi/2.1/ref/reference/option.presetStorage-docpage/
  }), myMap.geoObjects.add(myPlacemark); // , myPlacemark.balloon.open();
}
/** End YandexMaps Init Var1 **/

/** Start YandexMaps Init Run Var1 **/
function ymapsInitRun_var1() {
  'use strict';
  var $ = jQuery;
  if (typeof window.ymaps !== 'undefined') {
    ymaps.ready(ymapsInit_var1);
  }
}

/** End YandexMaps Init Run Var1 **/

/** Start YandexMaps Init ReRun Var1 **/
var resizeTimer;

function ymapsInitResizeRun_var1() {
  'use strict';
  var resizeTimer;
  var $ = jQuery;
  $('#gp-widget-yamaps-1').empty();
  clearTimeout(resizeTimer);
  resizeTimer = setTimeout(function() {
    ymapsInitRun_var1();
  }, 3000);
}

/** End YandexMaps Init ReRun Var1 **/

/** End YandexMaps Var1 **/

/** Start Copy to SideNav Run **/
function elementsCopyToSideNavRun() {
  'use strict';
  var $ = jQuery;
  $('.gh-logo-block').clone(true).appendTo('#gp-side-nav-logo-block');
  $('.gh-fastbtn-menu-block').clone(true).appendTo('#gp-side-nav-fastbtn-menu');
  $('.nav.navbar-nav').clone(true).appendTo('#gp-side-nav-menu-block');
}

/** End Copy to SideNav Run **/

/** End Global JS Functions **/

(function($) {
  'use strict';
  $(function() {
    /** Start Global Vars jQuery **/
    /** End Global Vars jQuery **/

    /** Start Global Functions jQuery **/
    $(document).ready(function() {
      /** Start All Title Hide **/
      allTitleHide();
      /** Start Input Mask Init **/
      inputMaskInit();
      /** Start Page ScrollUp Init **/
      pageScrollUpInit();
      /** Start Disabled Link Href Events **/
      disabledLinkHrefEventsInit();
      /** Start YandexMaps Init Run Var1 **/
      ymapsInitRun_var1();
      /** Start Copy to SideNav Run **/
      // elementsCopyToSideNavRun();

    });

    $(window).load(function() {
      /** Start Page Show Effect **/
      // gPagePreloader();
      /** Start Main Tag Calc min-height **/
      mainTagCalcMinHeight();

    });

    $(window).resize(function() {
      /** Start Main Tag Calc min-height **/
      mainTagCalcMinHeight();
      /** Start YandexMaps Init ReRun Var1 **/
      //ymapsInitResizeRun_var1();

    });
    /** End Global Functions jQuery **/
  });
})(jQuery);