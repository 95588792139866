/** Bootstrap AddOn Scripts **/

/** Start Global JS Vars **/
/** End Global JS Vars **/

/** Start Global BS JS Functions **/

/** Start Bootstrap Carousel with swipe (jQuery Swipe Plugin) **/
function _bs_carouselWSwipe_v1() {
  'use strict';
  var $ = jQuery;
  if ($.fn.swipe) {
    var $_bs_carouselCnt = $('.carousel');
    $_bs_carouselCnt.swipe({
      swipe: function(event, direction, distance, duration, fingerCount, fingerData) {
        if (direction === 'left') $(this).carousel('next');
        if (direction === 'right') $(this).carousel('prev');
      },
      allowPageScroll: 'vertical'
    }).children('.carousel-inner').addClass('carousel-inner-swipe');
  }
}

/** End Bootstrap Carousel with swipe (jQuery Swipe Plugin) **/

/** Start Bootstrap Carousel with swipe (jQuery Hammer Plugin) **/
function _bs_carouselWSwipe_v2() {
  'use strict';
  var $ = jQuery;
  if (typeof $.fn.hammer === 'function') {
    var $_bs_carouselCnt = $('.carousel');
    $_bs_carouselCnt.hammer().on('swipeleft', function() {
      $(this).carousel('next');
    });
    $_bs_carouselCnt.hammer().on('swiperight', function() {
      $(this).carousel('prev');
    });
    $_bs_carouselCnt.children('.carousel-inner').addClass('carousel-inner-swipe');
  }
}

/** End Bootstrap Carousel with swipe (jQuery Hammer Plugin) **/

/** Start Bootstrap Tooltip Init **/
function _bs_tooltipInit() {
  'use strict';
  var $ = jQuery;
  $('[data-toggle="tooltip"][data-title], [data-tooltip="tooltip"][data-title]').each(function() {
    var orig_title = $(this).attr('title');
    $(this).attr('data-orig-title', orig_title).removeAttr('title');
    $(this).tooltip({
      title: function() {
        return $(this).attr('data-title');
      }
    });
    $(this).attr('title', $(this).attr('data-orig-title'));
  });
  $('[data-toggle="tooltip"]:not([data-title]), [data-tooltip="tooltip"]:not([data-title])').tooltip();
}

/** End Bootstrap Tooltip Init **/

/** Start Bootstrap Popover Init **/
function _bs_popoverInit() {
  'use strict';
  var $ = jQuery;
  $('[data-toggle="popover"][data-title], [data-popover="popover"][data-title]').each(function() {
    var orig_title = $(this).attr('title');
    $(this).attr('data-orig-title', orig_title).removeAttr('title');
    $(this).popover({
      title: function() {
        return $(this).attr('data-title');
      }
    });
    $(this).attr('title', $(this).attr('data-orig-title'));
  });
  $('[data-toggle="popover"]:not([data-title]), [data-popover="popover"]:not([data-title])').popover();
}

/** End Bootstrap Popover Init **/

/** Start Fix Bootstrap Modal Tooltip and Popover **/
function _bs_fixTooltipModal() {
  'use strict';
  var $ = jQuery;
  $('.modal').on('show.bs.modal', function(e) {
    $('.tooltip').tooltip('hide');
  });
}

function _bs_fixPopoverModal() {
  'use strict';
  var $ = jQuery;
  $('.modal').on('show.bs.modal', function(e) {
    $('.popover').popover('hide');
  });
}

/** End Fix Bootstrap Modal Tooltip and Popover **/

/** Start Bootstrap Popover for Fastbtn Init **/
function _bs_popoverForFastbtnInit() {
  'use strict';
  var $ = jQuery;
  $('[data-toggle="popover_fastbtn"]').each(function() {
    if ($(this).attr('data-title') !== '') {
      var orig_title = $(this).attr('title');
      $(this).attr('data-orig-title', orig_title).removeAttr('title');
    }
    $(this).popover({
      title: function() {
        return $(this).attr('data-title');
      }
    }).on('mouseenter', function() {
      var _this = this;
      $(this).popover('show');
      $('.popover').on('mouseleave', function() {
        $(_this).popover('hide');
      });
    }).on('mouseleave', function() {
      var _this = this;
      setTimeout(function() {
        if (!$('.popover:hover').length) {
          $(_this).popover('hide');
        }
      }, 300);
    });
    if ($(this).attr('data-title') !== '') {
      $(this).attr('title', $(this).attr('data-orig-title'));
    }
  });
}

/** End Bootstrap Popover for Fastbtn Init **/

/** Start Fix Bootstrap Measure Scrollbar **/
function _bs_measureScrollbar() {
  'use strict';
  var $ = jQuery;
  var $body = $(document.body);
  var $_bs_modalCnt = $('.modal');
  var $genWrapperCnt = $('.g-page-wrapper');
  $_bs_modalCnt.on('show.bs.modal', function() {
    $genWrapperCnt.css('margin-right', -_bs_measureScrollbar_A1(), '!important');
  });
  $_bs_modalCnt.on('hidden.bs.modal', function() {
    $genWrapperCnt.removeAttr('style');
  });
}

function _bs_measureScrollbar_A1() {
  'use strict';
  var $ = jQuery;
  var $body = $(document.body);
  var scrollDiv = document.createElement('div');
  scrollDiv.className = 'modal-scrollbar-measure';
  $body.append(scrollDiv);
  var scrollbarWidth = scrollDiv.offsetWidth - scrollDiv.clientWidth;
  $body[0].removeChild(scrollDiv);
  return scrollbarWidth;
}

/** End Fix Bootstrap Measure Scrollbar **/

/** Start SelectMenu Bootstrap Tab Switch **/
function _bs_selectMenuTabInit() {
  'use strict';
  var $ = jQuery;
  var $elementSelect = $('.select-nav');
  var $elementSelectOpt = $('.select-nav-opt');
  if ($.fn.mask) {
    $elementSelect.each(function() {
      $(this).on('change', function(e) {
        var optSelTabTarget = $(this).children(':selected').attr('data-target');
        var tabContentID = (optSelTabTarget !== '' && optSelTabTarget !== undefined) ? optSelTabTarget : $(this).val();
        $('a[href="' + tabContentID + '"], a[data-target="' + tabContentID + '"]').tab('show');
      });
    });
  }
}

/** End SelectMenu Bootstrap Tab Switch **/

/** Start Addon Bootstrap Dropdown animation **/
function _bs_dropdownAnimation() {
  'use strict';
  var $ = jQuery;
  // http://bootbites.com/tutorials/bootstrap-dropdown-effects-animatecss
  // Bootstrap API hooks
  // =========================
  var $elementDropdown = $('.dropdown, .dropup');
  $elementDropdown.on({
    'show.bs.dropdown': function() {
      // On show, start in effect
      var dropdown = _bs_dropdownAnimation_Data(this);
      _bs_dropdownAnimation_Start(dropdown, dropdown.effectIn);
    },
    'shown.bs.dropdown': function() {
      // On shown, remove in effect once complete
      var dropdown = _bs_dropdownAnimation_Data(this);
      if (dropdown.effectIn && dropdown.effectOut) {
        _bs_dropdownAnimation_End(dropdown, function() {});
      }
    },
    'hide.bs.dropdown': function(e) {
      // On hide, start out effect
      var dropdown = _bs_dropdownAnimation_Data(this);
      if (dropdown.effectOut) {
        e.preventDefault();
        _bs_dropdownAnimation_Start(dropdown, dropdown.effectOut);
        dropdown.dropdown.removeClass('animate-open');
        _bs_dropdownAnimation_End(dropdown, function() {
          dropdown.dropdown.removeClass('open');
        });
      }
    }
  });
  $elementDropdown.hover(function() {
    var $dropdownToggle = $('.dropdown-toggle', this);
    if (!($(this).hasClass('open'))) {
      $dropdownToggle.dropdown('toggle');
      $dropdownToggle.blur();
    }
  }, function() {
    var $dropdownToggle = $('.dropdown-toggle', this);
    if ($(this).hasClass('open')) {
      $dropdownToggle.dropdown('toggle');
      $dropdownToggle.blur();
    }
  });
  $('.dropdown-menu').on('click.bs.dropdown', function(e) {
    // Bootstrap Navbar dropdown-menu block close it on click
    e.stopPropagation();
  });
}

function _bs_dropdownAnimation_Data(target) {
  'use strict';
  var $ = jQuery;
  // Custom function to read dropdown data
  // =========================
  // @todo - page level global?
  var effectInDefault = null,
    effectOutDefault = null;
  var dropdown = $(target),
    dropdownMenu = $('.dropdown-menu', target);
  var parentUl = dropdown.parents('ul.nav');
  // If parent is ul.nav allow global effect settings
  if (parentUl.length > 0) {
    effectInDefault = parentUl.data('dropdown-in') || null;
    effectOutDefault = parentUl.data('dropdown-out') || null;
  }
  return {
    target: target,
    dropdown: dropdown,
    dropdownMenu: dropdownMenu,
    effectIn: dropdownMenu.data('dropdown-in') || effectInDefault,
    effectOut: dropdownMenu.data('dropdown-out') || effectOutDefault
  };
}

function _bs_dropdownAnimation_Start(data, effectToStart) {
  'use strict';
  var $ = jQuery;
  // Custom function to start effect (in or out)
  // =========================
  if (effectToStart) {
    data.dropdown.addClass('animate-open');
    data.dropdown.addClass('dropdown-animating');
    data.dropdownMenu.addClass('animated');
    data.dropdownMenu.addClass(effectToStart);
  }
}

function _bs_dropdownAnimation_End(data, callbackFunc) {
  'use strict';
  var $ = jQuery;
  // Custom function to read when animation is over
  // =========================
  var animationEnd = 'webkitAnimationEnd mozAnimationEnd MSAnimationEnd oanimationend animationend';
  data.dropdown.one(animationEnd, function() {
    data.dropdown.removeClass('dropdown-animating');
    data.dropdownMenu.removeClass('animated');
    data.dropdownMenu.removeClass(data.effectIn);
    data.dropdownMenu.removeClass(data.effectOut);
    // Custom callback option, used to remove open class in out effect
    if (typeof callbackFunc === 'function') {
      callbackFunc();
    }
  });
}

/** End Addon Bootstrap Dropdown animation **/

/** Start Bootstrap Navbar with Affix plugin **/
function _bs_navbarAffixInit() {
  'use strict';
  var $ = jQuery;
  var navbar_affix_el_spy = '[data-spy="affix-navbar-top"]';
  var $navbar_affix_sec_el = false;
  if ($(window).width() >= 768) { // Breakpoin plugin is activated (768px)
    $(navbar_affix_el_spy).each(function() {
      var navbar_affix_el = $(this).attr('data-spy-offset-top');
      $(this).affix({
        offset: {
          top: function() {
            return (this.top = $(navbar_affix_el).offset().top);
          }
        }
      });
      if ($navbar_affix_sec_el) {
        $(this).on('affix.bs.affix', function() {
          $navbar_affix_sec_el.addClass('affixToo');
        }).on('affixed-top.bs.affix', function() {
          $navbar_affix_sec_el.removeClass('affixToo');
        });
      }
    });
  }
}

function _bs_navbarAffixResizeInit() {
  'use strict';
  var $ = jQuery;
  var resizeTimer;
  var navbar_affix_el_spy = '[data-spy="affix-navbar-top"]';
  var $navbar_affix_sec_el = $('.gh-fastbtn-menu-block');
  clearTimeout(resizeTimer);
  resizeTimer = setTimeout(function() {
    if ($(window).width() >= 768) { // Breakpoin plugin is activated (768px)
      $(navbar_affix_el_spy).each(function() {
        var navbar_affix_el = $(this).attr('data-spy-offset-top');
        $(this).affix({
          offset: {
            top: function() {
              return (this.top = $(navbar_affix_el).offset().top);
            }
          }
        });
        $(this).affix('checkPosition');
        $(this).on('affix.bs.affix', function() {
          $navbar_affix_sec_el.addClass('affixToo');
        }).on('affixed-top.bs.affix', function() {
          $navbar_affix_sec_el.removeClass('affixToo');
        });
      });
    } else { // Disabling and clearing plugin data if screen is less 768px
      $(navbar_affix_el_spy).each(function() {
        $(window).off('.affix');
        $(this).removeData('bs.affix').removeClass('affix affix-top affix-bottom');
      });
    }
  }, 200);
}

/** End Bootstrap Navbar with Affix plugin **/

/** Start Bootstrap SideNav plugin **/
// function is_touch_device() {
//   try {
//     document.createEvent("TouchEvent");
//     return true;
//   } catch (e) {
//     return false;
//   }
// }
//
// if (Modernizr.touchevents) {
//   if (is_touch_device()) {
//     $('.side-nav').css({
//       overflow: 'auto'
//     });
//   }
//
//   $(".navbar-toggle").sideNav({
//     menuWidth: 300,
//     edge: 'left',
//     closeOnClick: false,
//     draggable: true,
//     dragTargetWidth: '20px'
//   });
// }
/** End Bootstrap SideNav plugin **/

/** End Global BS JS Functions **/

(function($) {
  'use strict';
  $(function() {
    /** Start Global Vars jQuery **/
    /** End Global Vars jQuery **/

    /** Start Global Functions jQuery **/
    $(document).ready(function() {
      /** Start Bootstrap Carousel with swipe (jQuery Hammer Plugin) **/
      _bs_carouselWSwipe_v2();
      /** Start Bootstrap Tooltip Init **/
      _bs_tooltipInit();
      /** Start Bootstrap Popover Init **/
      _bs_popoverInit();
      /** Start Fix Bootstrap Modal Tooltip and Popover **/
      _bs_fixTooltipModal();
      _bs_fixPopoverModal();
      /** Start Fix Bootstrap Measure Scrollbar **/
      _bs_measureScrollbar();
      /** Start SelectMenu Bootstrap Tab Switch **/
      _bs_selectMenuTabInit();
      /** Start Addon Bootstrap Dropdown animation **/
      _bs_dropdownAnimation();
      /** Start Bootstrap Navbar with Affix plugin **/
      _bs_navbarAffixInit();

    });

    $(window).load(function() {

    });

    $(window).resize(function() {
      /** Start Bootstrap Navbar with Affix plugin **/
      _bs_navbarAffixResizeInit();

    });
    /** End Global Functions jQuery **/
  });
})(jQuery);